import { graphql } from 'gatsby';
import { toJS } from 'mobx';
import React from 'react';
import { WpPageSectionAssociations } from '../../../types/wordpress.types';
import PageSection from '../../PageSection/PageSection';
import PageSectionContentOnImage from '../../PageSectionContentOnImage/PageSectionContentOnImage';
import './PageSectionAssociations.scss';

export const PageSectionAssociationsFragment = graphql`
  fragment PageSectionAssociationsFragment on WpTemplate_FrontPage {
    frontPageFields {
      sectionAssociations {
        filmBodies {
          ... on WpProduct {
            ...ProductWithFeaturedAlternateImageFragment
          }
        }
        nationalBodies {
          ... on WpProduct {
            ...ProductWithFeaturedAlternateImageFragment
          }
        }
        studios {
          ... on WpProduct {
            ...ProductWithFeaturedAlternateImageFragment
          }
        }
        suppliers {
          ... on WpProduct {
            ...ProductWithFeaturedAlternateImageFragment
          }
        }
      }
    }
  }
`;

type PageSectionAssociationsProps = WpPageSectionAssociations & {
}

const PageSectionAssociations: React.FC<PageSectionAssociationsProps> = props => {
  const p = props;
  const s = {
    get associateTypeToValuesMap() {
      return {
        'Film Bodies': p.filmBodies,
        'National Bodies': p.nationalBodies,
        'Studios': p.studios,
        'Suppliers': p.suppliers,
      }
    }
  }
  return <PageSection id="PageSectionAssociations" className="page-section PageSectionAssociations" observeVisibility>

    <main className="PageSectionAssociations__Main">
      {Object.entries(s.associateTypeToValuesMap).map(([type, values], idx) => {
        const alignment = idx % 2 === 0 ? 'left' : 'right';
        return <PageSectionContentOnImage
          key={idx}
          id={`AssociationsSection-${idx}`}
          className="AssociationsSection"
          contentPosition={alignment}
          content={{
            heading: type,
            doNotIncludeRelatedMediaSpacer: true,
            relatedMedia: values?.map(p => {
              const media = p.productFields?.alternateImage ?? p.featuredImage?.node;
              const link = p.productFields?.actionLink;
              const res = { ...toJS(media), actionLink: link };
              return res;
            }),
            relatedMediaAsActionLink: true,
            relatedMediaObjectFit: 'contain',
          }}
          isSwiper={true}
        />
      }
      )}
    </main>

  </PageSection>
}

export default PageSectionAssociations;